import * as React from 'react';
import { Image, Text, TouchableOpacity, View, Linking, FlatList } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export class Queue extends React.PureComponent{
    constructor(props){
        super(props);
    }

    shortenName = (name) => {
        let nameArray = name.split(" ");
        if (nameArray.length > 1) {
          return nameArray[0] + " " + nameArray[1].charAt(0) + "."
        } else {
          return nameArray[0]
        }
      }

    render(){
        return (
            <View style={this.props.style}>                                
                <FlatList
                    data={this.props.data}  
                    contentContainerStyle={{alignSelf: 'flex-start'}}                      
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    initialNumToRender={this.props.initialNumRender}                  
                    renderItem={({ item }) => {
                        return (
                            
                            <View style={{marginRight: 20, alignItems: 'center'}}>
                                <Text numberOfLines={2} style={{textAlign: 'center', fontSize: 'italic', width: 120, color: '#808080', fontSize: 14, marginBottom: 7}}>
                                    Added by {this.shortenName(item.user)}
                                </Text>  
                                <TouchableOpacity>
                                    <Image
                                        source={item.image}
                                        style={{resizeMode: 'contain', height: 120, width: 120, borderRadius: 10}}
                                    />                                                    
                                    <Text numberOfLines={2} style={{width: 120, textAlign: 'center', fontWeight: 'bold', color: '#1F1F1F', fontSize: 12, marginTop: 10, flex: 1, flexShrink: 1}}>
                                        {item.name}
                                    </Text>
                                    <Text numberOfLines={2} style={{width: 120, textAlign: 'center', color: '#626262', fontSize: 12, marginTop: 10, marginBottom: 30}}>
                                        {item.artists}
                                    </Text>
                                </TouchableOpacity>  
                            </View>                            
                        );
                    }}
                    keyExtractor={item => item.name}
                />                
            </View>
        )
    }
}

Queue.navigationOptions = {
    header: null,
  };