import * as React from 'react';
import { Image, Text, TouchableOpacity, TextInput, View, Linking, FlatList } from 'react-native';
import Button from './Button';
import Avatar from './Avatar';
import { ScrollView } from 'react-native-gesture-handler';


export default class QueueModal extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            selectedSong: null,
            searchText: "",
            gettingResults: false,
            searchResults: [],
            addingSong: false
        }
    }

    onChangeText = (text) => {                
        if (this.state.gettingResults) {
            this.setState({searchText: text});
        } else {            
            if (!!text) {
                this.getSongs(text);  
                this.setState({gettingResults: true})
            } else {
                this.setState({searchText: text});
            }
        }
    }

    getSongs = (text) => {    
        var body = {          
          workspace_userid: this.props.user.workspace_userid,
          search_text: text
        }
        if ('tokens' in this.props.user) body.tokens = this.props.user.tokens
        if ('link' in this.props) body.link = this.props.link
        var callback = (data) => {                 
            this.setState({                
                searchResults: data,
                gettingResults: false       
            }); 
        }        
        this.props.api.post(
          'https://api.spotifybuddy.com/searchSong', 
          body,
          callback=callback 
        )
    }

    queueSong = () => {    
        if (!!!this.state.selectedSong || this.state.addingSong) return;
        this.setState({addingSong: true})
        this.props.onClose();
        var body = {          
          workspace_userid: this.props.user.workspace_userid,
          uri: this.state.selectedSong,
          uniqueid_type: this.props.sessionID
        }      
        if ('tokens' in this.props.user) body.tokens = this.props.user.tokens
        if ('link' in this.props) body.link = this.props.link
        var callback = (data) => {                                         
            this.setState({gettingResults: false, addingSong: false})
        }

        var error = (err) => {                 
            alert("Error: " + err);
        }
    
        this.props.api.post(
          'https://api.spotifybuddy.com/queueSong', 
          body,
          callback=callback 
        )
    }    

    render(){
        var styling ={
            cellWidth: this.props.size.width * 0.64,
            textLength: (this.props.size.width * 0.64) - 100            
        }
        return (
            <View style={[styles.modalContent , {width: this.props.size.width * 0.8, height: this.props.size.height * 0.95, justifyContent: "flex-start", alignItems: "center"}]}>
                <TouchableOpacity style={{alignSelf: 'flex-start'}} onPress={this.props.onClose}>
                    <Image 
                        source={require('../assets/images/close.png')}
                        style={{height: 20, width: 20}}
                    />
                </TouchableOpacity>

                <Text style={{fontWeight: 'bold', color: '#4C4C4C', fontSize: 20, marginBottom: 10}}>
                     Add a Song
                </Text>  
                <View style={styles.searchSection}>
                    <Text style={{paddingLeft: 10}}>🔍</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Search"
                        selectionColor={'transparent'}
                        onChangeText={this.onChangeText}                        
                    />                
                </View>      
                {this.state.searchResults.length == 0 &&
                 <Text>
                    Search for songs to add!
                 </Text>   
                }                      
                {this.state.searchResults.length > 0 &&
                <FlatList
                    data={this.state.searchResults}  
                    contentContainerStyle={{alignItems: 'center'}}
                    showsVerticalScrollIndicator={false}
                    initialNumToRender={10}                       
                    renderItem={({ item }) => {                        
                        return (
                            <TouchableOpacity style={{marginBottom: 20}} onPress={() => this.setState({selectedSong: item.uri})}>
                                <View style={{borderRadius: 5, padding: 10, borderWidth: 1, borderColor: '#D8D8D8', width: styling.cellWidth, height: 70, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Image
                                            source={item.image}
                                            style={{height: 50, width: 50, resizeMode: 'contain', borderRadius: 10, marginRight: 10}}
                                        />
                                        <View>
                                            <Text numberOfLines={2} style={{width: styling.textLength, fontSize: 14, color: '#4C4C4C'}}>
                                                {item.name}
                                            </Text>
                                            <Text numberOfLines={2} style={{width: styling.textLength, fontSize: 14, color: '#808080'}}>
                                                {item.artists}
                                            </Text>
                                        </View>
                                    </View>
                                
                                    <Image
                                        source={item.uri === this.state.selectedSong ? require('../assets/images/check.png') : require('../assets/images/add.png')}
                                        style={{height: 20, width: 20, resizeMode: 'contain'}} 
                                    />
                                </View>
                            </TouchableOpacity>
                        );
                    }}
                    keyExtractor={item => item.uri}
                />
                }         
                { !!this.state.selectedSong && 
                <Button 
                    fontSize={14} textColor={'white'} backgroundColor={'#00D359'} borderRadius={5}
                    width={120} height={40} text={"Add Song"}
                    onPress={this.queueSong} style={{marginTop: 10}}
                  /> 
                }
            </View>
        )
    }
}

QueueModal.navigationOptions = {
    header: null,
  };

const styles = {
    button: {
        backgroundColor: "lightblue",
        padding: 12,
        margin: 16,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.1)"
      },
      modalContent: {
        backgroundColor: "white",
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 20,
        paddingBottom: 10,        
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.1)",        
        alignSelf: 'center'
      },
      searchSection: {
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: '#D8D8D8',
        borderWidth: 2,
        borderRadius: 5,
        marginBottom: 10,
        width: '80%'
    },
    searchIcon: {
        padding: 10,
    },
    input: {
        flex: 1,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10, 
        paddingLeft: 10,               
        outline: 'none'     
    }
}