import * as React from 'react';
import { Image, Text, TouchableOpacity, View, Linking, FlatList } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Avatar from './Avatar';

export default class Listeners extends React.PureComponent{
    constructor(props){
        super(props);
    }
    render(){        
        return (
            <View style={this.props.style}>
                <Text style={{fontWeight: 'bold', color: '#4C4C4C', fontSize: this.props.fontSize ? this.props.fontSize :22, marginBottom: 10}}>
                    {this.props.data.length.toString() + " Listener"}{this.props.data.length != 1 ? "s" : ""}
                </Text>
                <ScrollView horizontal={true} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
                    <FlatList
                        data={this.props.data}  
                        contentContainerStyle={{alignSelf: 'flex-start'}}                      
                        numColumns={Math.ceil(this.props.data.length/this.props.numRows)} 
                        initialNumToRender={this.props.initialNumRender}   
                        key={this.props.data.length}               
                        renderItem={({ item }) => {
                            let nameArray = item.name.split(" ");
                            let name = nameArray[0] + (nameArray.length > 1 ? " " + nameArray[1].charAt(0) + "." : "")
                            return (
                                <TouchableOpacity>
                                    <View style={{marginBottom: 7, padding: 3, marginRight: 7, borderRadius: 5, borderWidth: 1, borderColor: '#D8D8D8', height: 40, width: 130, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                        <Avatar
                                            source={item.image}
                                            style={{marginRight: 5}}
                                        />
                                        <Text style={{fontSize: 14, color: '#808080'}}>
                                            {name}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            );
                        }}
                        keyExtractor={item => item.workspace_userid}
                    />
                </ScrollView>
            </View>
        )
    }
}