import * as React from 'react';
import { Text, View} from 'react-native';

export default class Banner extends React.PureComponent{
    constructor(props){
        super(props)
        this.state={count: 0}
    }

    componentDidMount(){
        this.setState({count: 1})
        this.myInterval = setInterval(() => {                        
            if (this.state.count%this.props.timeShown === 0) {
                this.props.hide();
                clearInterval(this.myInterval);
            } 
            this.setState({count: this.state.count + 1})
        }, 1000)
    }

    componentWillUnmount(){
        clearInterval(this.myInterval)
    }

    render(){
        return(
            <View style={{width: this.props.width, height: 50, backgroundColor: !!this.props.backgroundColor ? this.props.backgroundColor : '#ee6969', alignItems: 'center', justifyContent: 'center', borderRadius: 5, padding: 10}}>                
                <Text style={{color: 'white', fontSize: 15, textAlign: 'center'}}>
                    {this.props.text}
                </Text>
            </View>
        );
    }
}