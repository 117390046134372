import * as React from 'react';
import { Text, View, TouchableOpacity, Image, StyleSheet} from 'react-native';
import Avatar from './Avatar';
import { Linking } from 'react-native-web';

export class Header extends React.PureComponent{
  constructor(props){
    super(props);    
    this.state={
      user: 'user' in props ? props.user : null
    }
  }
  render(){    
    return (
      <View style={styles.MainContainer}>
        <View style={{width: this.props.width ? this.props.width : '90%', height: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={()=> this.props.navigation.navigate('Home')}>
          <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', height: '100%'}}>
            <Image
              source={require('../assets/images/spotify_buddy.png')}
              style={{resizeMode: 'contain', width: 40, height: 40}}
            />            
            <Text style={{fontWeight: '700', fontSize: this.props.width >=400 ? 20 : 15}}>
                Spotify Buddy
            </Text>            
          </View>           
        </TouchableOpacity> 
        
        <View style={{flexDirection: 'row', alignItems: 'center', height: '100%'}}>          
            <View accessibilityRole="link" target="_blank" href="https://www.buymeacoffee.com/spotifybuddy" style={{height: 30, width: 100, marginRight: 10, borderColor: '#D3D3D3', borderWidth: 1, borderRadius: 5, justifyContent: 'center'}}>
              <Text style={{textAlign: 'center'}}>
                Donate
              </Text>
            </View>          
          {this.state.user && 
          <TouchableOpacity onPress={() => this.props.navigation.navigate('Profile')}>
          <Avatar
              source={!!this.state.user.image ? this.state.user.image : ""}
              size={40}              
            />
          </TouchableOpacity>
          }
        </View>        
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  MainContainer :{
    flexDirection: 'row', 
    alignItems: 'center',
    height: 60, 
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#D3D3D3',
    justifyContent: 'center'
  },
  childView: { 
    width: 100,
    height: 100,
    borderBottomWidth :5,
    borderBottomColor: '#000',
    backgroundColor: '#00BCD4',
  }
});