import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View, Linking, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../components/Header';
import ScaledImage from '../components/ScaledImage'

export default class LandingScreen extends React.PureComponent{
  constructor(props){
    super(props);
    this.state= {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height
    }
    Dimensions.addEventListener("change", this.handler);
  }

  handler = (dims) => {    
    this.setState({
      width: dims.window.width,
      height: dims.window.height,
    });
  }

  verifySession = (user) => {
    fetch('https://api.spotifybuddy.com/user/verifySession', {
      method: 'post',
      credentials: 'include',
      body: user
    })
      .then((response) => {        
        return response.json()
      })
      .then((data) => {            
        if (data.ok){          
          this.props.navigation.navigate('Home');
        }
      })
      .catch(err => {
        console.log("Error:")
        console.log(err)
      })
  }
  
  componentDidMount(){        
    const userSession = localStorage.getItem('user');    
    if (userSession){      
      this.verifySession(userSession);
    }
  }

  componentWillUnmount() {
    // Important to stop updating state after unmount
    Dimensions.removeEventListener("change", this.handler);
  }

  render(){
    let widthCutoff = 950;
    var styling = {}
    if (this.state.width <= widthCutoff){
      styling.container ={
        flexDirection: 'column',
        alignItems: 'center',
        width: '95%',
        // height: 1000,
        flexShrink: 1,        
      }
      styling.text = {
        headerFontSize: 40,
        descripFontSize: 18,
        smallHeadFontSize: 30
      }
      styling.image={
        height: this.state.height * 0.4
      }
      styling.imagePadding = 15
      styling.buttonWidth = this.state.width < 600 ? '100%' : 500
    }else{
      styling.container = {
        // flexDirection: this.state.width <=widthCutoff ? 'column':'row', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: this.state.width >= 1485 ? 1485 : '90%', 
        // height: 500,        
      }
      styling.text = {
        headerFontSize: 60,
        descripFontSize: 24,
        smallHeadFontSize: 40
      }
      styling.image={
        height: this.state.height * 0.45,
      }
      styling.buttonWidth = 500
      styling.imagePadding = 30
    }    
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation}/>        
        <ScrollView contentContainerStyle={[styles.contentContainer, {paddingTop: styling.imagePadding}]}>                     
          <View style={{width: '90%', alignItems: 'center', flexShrink: 1}}>
            <ScaledImage
              source={require('../assets/images/cover.svg')}
              height={styling.image.height}
              screenWidth={this.state.width * 0.9}                   
            />
            <Text style={{fontWeight: '900', fontSize: styling.text.headerFontSize, textAlign: 'center', marginTop: 25}}>
              Listen to Spotify together
            </Text>
            <Text style={{fontSize: styling.text.descripFontSize, marginTop: 20, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)'}}>
              Jam sessions, socially distanced. Listen to music with others, wherever you are. No aux needed.
            </Text>
            <TouchableOpacity style={{marginTop: 30, marginBottom: 2, width: styling.buttonWidth}} onPress={() => Linking.openURL('https://api.spotifybuddy.com/user/authWeb')}>
              <View style={{backgroundColor: '#1DB954',  height: 50, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', borderRadius: 10}}>
                  <Image
                    source={require('../assets/images/spotify_white.png')}
                    style={{resizeMode: 'contain', height: '60%', width: '10%', flexShrink: 1}}
                  />
                  <Text style={{fontSize: 20, fontWeight: '900', color: 'white', marginLeft: 5}}>
                    START JAMMING
                  </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{marginTop: 20, width: 170}} onPress={() => Linking.openURL("https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands,chat:write&client_id=886366223297.969263842279")}>
              <View style={{height: 50, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', borderRadius: 10}}>
                  <Image
                    source={'https://platform.slack-edge.com/img/add_to_slack@2x.png'}
                    style={{resizeMode: 'contain', height: '100%', width: '100%'}}
                  />
              </View>
            </TouchableOpacity>
          </View>
          <View style={{width: '90%', marginTop: 50, borderTopWidth: 1, borderTopColor: '#D3D3D3', alignItems: 'center'}}>
            <ScaledImage
              source={require('../assets/images/pic1.png')}
              height={styling.image.height * 0.6}
              screenWidth={this.state.width * 0.9}  
              style={{marginTop: 50}}                 
            />
            <Text style={{fontWeight: '900', fontSize: styling.text.smallHeadFontSize, textAlign: 'center', marginTop: 25}}>
              Listen along, in real-time
            </Text>
            <Text style={{fontSize: styling.text.descripFontSize, marginTop: 20, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)'}}>
              Host your own silent disco party. Run to the same beats as friends. Or jam with someone on the other side of the world. Listening to music together in real-time has never been easier.
            </Text>
          </View>
          <View style={{width: '90%', marginTop: 50, borderTopWidth: 1, borderTopColor: '#D3D3D3', alignItems: 'center'}}>
            <ScaledImage
              source={require('../assets/images/pic2a.png')}
              height={styling.image.height * 0.6}
              screenWidth={this.state.width * 0.9}
              style={{marginTop: 50}}                            
            />
            <Text style={{fontWeight: '900', fontSize: styling.text.smallHeadFontSize, textAlign: 'center', marginTop: 25}}>
              Share the aux
            </Text>
            <Text style={{fontSize: styling.text.descripFontSize, marginTop: 20, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)'}}>
              No more passing the phone around to queue songs. Anyone can add songs directly to the queue from their own phone, even if they aren’t a Spotify user. 
            </Text>
          </View>
          <View style={{width: '90%', marginTop: 50, borderTopWidth: 1, borderTopColor: '#D3D3D3', alignItems: 'center'}}>                                 
            <ScaledImage
              source={require('../assets/images/pic3.png')}
              height={styling.image.height * 0.6}
              screenWidth={this.state.width * 0.9}
              style={{marginTop: 50}}                            
            />
            <Text style={{fontWeight: '900', fontSize: styling.text.smallHeadFontSize, textAlign: 'center', marginTop: 25}}>
              Slacking off?
            </Text>
            <Text style={{fontSize: styling.text.descripFontSize, marginTop: 20, textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)'}}>
              Take a quick break to learn about your coworkers’ music tastes or start a jam session while you grind away - all on your favorite workplace messaging platform.
            </Text>
          </View>          
          <View style={{margin: 75}}>
            <Text style={{fontSize: 15, textAlign: 'center'}}>
              {"Made with ❤️, 🎧, and 😭 by "}
              <Text style={{textDecorationLine: 'underline'}} onPress={() => Linking.openURL('https://jonathanwong.io')}>
                Jonathan Wong
              </Text>
            </Text>
            <View style={{marginTop: 10, alignSelf: 'center'}} accessibilityRole="link" target="_blank" href="https://forms.gle/qZkCa7qzd41uWUh96">
                <Text style={{color: 'gray', textDecorationLine: 'underline', fontWeight: '300'}}>
                  Give Feedback
                </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

LandingScreen.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: 'center',
    flexGrow: 1
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
});
