import * as React from 'react';
import { Image, Text, TouchableOpacity, TextInput, View, Linking, FlatList } from 'react-native';
import Button from './Button';

export default class FormInput extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            selectedSong: "",
            searchText: "",
            searchResults: []
        }
    }

    onChangeText = (text) => {        
        this.setState({searchText: text})
    }

    render(){
        var colors = {}
        // if(!!this.props.valid){
        //     if (this.props.valid){
        //         // colors.backgroundColor = '#B7E7CB';
        //         // colors.borderColor = '#6FCF97';
        //     }else{
        //         // colors.backgroundColor = '#F5ABAB';
        //         // colors.borderColor = '#ED6868';
        //     }            
        // }else{
        //     colors.backgroundColor = '#fff';
        //     colors.borderColor = '#D8D8D8';
        // }
        return (
            <View style={[styles.searchSection, colors]}>
                <Text style={{color: '#C5C5C5'}}>{this.props.initialText}</Text>
                <TextInput
                    style={[styles.input, {paddingLeft: !!this.props.padInput ? this.props.padInput : 0}]}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    selectionColor={'transparent'}
                    onChangeText={this.props.onChangeText}                        
                />                
                {this.props.valid != null && 
                <Text style={{color: this.props.valid ? '#6FCF97' : '#ED6868'}}>
                    {this.props.valid ? "✅" : "❌"}
                </Text>
                }
            </View>      
        )
    }
}

FormInput.navigationOptions = {
    header: null,
  };

const styles = {
      searchSection: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: '#D8D8D8',
        borderWidth: 2,
        borderRadius: 5,
        marginBottom: 10,
        padding: 10  
    },
    searchIcon: {
        padding: 10,
    },
    input: {
        flex: 1,                             
        outline: 'none'              
    }
}