import * as React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View, Linking, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../../components/Header';
import Button from '../../components/Button';
import Listeners from '../../components/Listeners';
import Loading from '../../components/Loading';
import Banner from '../../components/Banner';
import Avatar from '../../components/Avatar';
import webAPI from '../../utils/web_api/api';
import ReconnectingWebSocket from '../../utils/web_api/ws';
import {copyToClipboard} from '../../utils/util';

const api = new webAPI();

export default class SyncScreen extends React.PureComponent{
  constructor(props){
    super(props);    
    this.state = {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
      link: this.props.route.params.id + "_" + this.props.route.name.toLowerCase(),
      sessionEnded: false,
      isPlaying: false,
      isError: false,
      loading: false,
      host: false
    }
    if(!this.state.user) {
      this.guestSession()  
    } else {
      this.verifySession(this.state.user.workspace_userid);      
    }            
  }

  ws = null;

  componentDidMount() {
    Dimensions.addEventListener("change", this.handler);    
  }

  componentWillUnmount() {        
    if (!!this.ws) this.ws.close();    
    Dimensions.removeEventListener("change", this.handler);
  }

  handler = (dims) => {    
    this.setState({
      width: dims.window.width,
      height: dims.window.height,
    });
  }

  wsConnect = (tokens, id) => {    
    this.ws = new ReconnectingWebSocket(
      'wss://31egu007hh.execute-api.us-east-1.amazonaws.com/production?session=' + this.state.link
      + "&accessToken=" + tokens.accessToken + "&refreshToken=" + tokens.refreshToken
      + "&id=" + id  , 
      null, {maxReconnectAttempts: 1}
    );
    
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected')
    }

    this.ws.onmessage = event => {      
      // listen to data sent from the websocket server      
      const data = JSON.parse(event.data)         
      if (data.type === 'track'){
        this.setState({
          track: data.track
        })
      }
      if (data.type === 'end'){
        this.endSession();
      }
      if (data.type === 'join'){
        this.setState({
          listeners: [data.user].concat(this.state.listeners)
        })
      }
      if (data.type === 'leave'){
        this.setState({
          listeners: this.state.listeners.filter((user) => user.workspace_userid !== data.user.workspace_userid)
        })
      }
    }

    this.ws.onclose = () => {
      console.log('disconnected')
      // automatically try to reconnect on connection loss
    }
  }

  getSession = (link, userID, tokens) => {
    var callback = (data) => {  
      //check if currently listening
      var listening = false;
      for (var listener in data.session.listeners) {
        if (data.session.listeners[listener].workspace_userid === userID) listening = true;
      }                            
      this.setState({                        
        session: data.session,
        listeners: data.session.listeners,
        track: data.track,
        auth: tokens,
        isPlaying: listening,
        host: (userID === data.session.host.workspace_userid),         
      });  
      if (!(userID === data.session.host.workspace_userid) && !listening){ 
        this.setState({bannerText: "❕Before clicking join session, make sure to have a song playing in Spotify to establish a connection"});
      }
      this.wsConnect(JSON.parse(tokens), userID);     
    }
    var error = (err) => {          
      this.setState({sessionEnded: true})       
    }
    var body = {link: link, workspace_userid: userID, tokens: tokens}    
    api.post(
      'https://api.spotifybuddy.com/getSession', 
      body,
      callback=callback,
      error=error
    )    
  }

  guestSession = () => {
    var callback = (data) => {            
      this.getSession(this.state.link, 'guest', data.auth);                   
    }
    var error = (err) => {                  
    }
    api.post(
      'https://api.spotifybuddy.com/user/guestSession', 
      {},
      callback=callback,
      error=error
    )
  }

  verifySession = (workspace_userid) => {
    var callback = (data) => {          
      this.getSession(this.state.link, workspace_userid, data.auth);      
    }
    var error = (err) => {    
      //user is not logged in          
      localStorage.setItem('session', JSON.stringify(this.state.session));
      Linking.openURL('https://api.spotifybuddy.com/user/authWeb');              
    }
    api.post(
      'https://api.spotifybuddy.com/user/verifySession', 
      {workspace_userid: workspace_userid},
      callback=callback,
      error=error
    )
  }  

  returnHome = () => {    
    this.props.navigation.navigate('Home', {session: 'ended'});
  }

  share = () => {
    this.setState({
      bannerText: "🔗  Invite link copied to clipboard"
    });    
    copyToClipboard(window.location.href);
  }

  buttonStyles = () => {
    if (this.state.host){ 
      return ["End Session", '#9B9B9B'];
    } else {
      if(this.state.isPlaying) {
        return ["Leave Session", '#9B9B9B']
      } else {
        return ["Join Session", '#00D359']
      }
    }
  }

  bottomBTAction = () => {    
    this.setState({loading: true});
    var callback;
    var error = (err) => {
      this.setState({bannerText: "😔 Error: " + err.error, isError: true, loading: false});
    }
    if (this.state.host){ 
      //end session
      callback = (data) => {
        this.endSession();
        this.setState({loading: false});
      }
      this.sessionAction(callback, error, 'end');
    } else {
      if(this.state.isPlaying) {        
        //leave session
        callback = (data) => {
          localStorage.removeItem('session');
          this.setState({
            isPlaying: false,
            loading: false
          })
        }
        this.sessionAction(callback, error, 'leave');
      } else {
        //join session
        if (!!this.state.user) {
          //user is logged in
          callback = (data) => {
            this.setState({
              isPlaying: true,
              loading: false
            })
          }
          this.sessionAction(callback, error, 'join');
        } else {
          //user is not logged in          
          localStorage.setItem('session', JSON.stringify(this.state.session));
          Linking.openURL('https://api.spotifybuddy.com/user/authWeb');
        }
      }
    }
  }

  endSession = () => {
    localStorage.removeItem('session');
    this.ws.close();
    this.setState({session: null, sessionEnded: true});
  }

  sessionAction = (callback, error, actionType) => {  
    api.post(
      'https://api.spotifybuddy.com/user/sessionAction', 
      {
        link: this.state.link, 
        action: actionType, 
        workspace_userid: this.state.user.workspace_userid
      },
      callback=callback,
      error=error
    )
  }  

  render(){    
    let newWidth = this.state.width <= 1000 ? this.state.width * 0.95 : 1000; 
    var styling = {};         
    let bottomBT = this.buttonStyles(); 
    if (this.state.width <= 700){
        styling = {
            headerWidth: '90%',
            mainHeader: 24,
            subHeader: 14,
            hostImageMarginLeft: 0,
            alignment: 'flex-start',
            mobileMargin: 10,
            albumSize: this.state.width * 0.8,
            albumBorder: 10,
            inviteBTWidth: 100,
            inviteBTHeight: 30,
            endBTWidth: this.state.width * 0.5,
            endBTHeight: 40, 
            buttonFontSize: 14,
            layoutDirection: 'column',
            infoMaxWidth: this.state.width * 0.9,
            infoMarginLeft: 0,
            current: 14,
            title: 28,
            artist: 25
        }
      }else{
        styling = {
          headerWidth: '100%',
          mainHeader: 30,
          subHeader: 20,
          hostImageMarginLeft: 5,
          alignment: 'center',
          mobileMargin: 0,
          albumSize: 300,
          albumBorder: 10,
          inviteBTWidth: 200,
          inviteBTHeight: 45,
          endBTWidth: 375,
          endBTHeight: 50, 
          buttonFontSize: 20,
          layoutDirection: 'row',
          infoMaxWidth: newWidth - 340,
          infoMarginLeft: 40,
          current: 22,
          title: 40,
          artist: 36
        }
      }
    
    //session doesn't exist
    if (this.state.sessionEnded) {
      return (
        <View style={[styles.container, {alignItems: 'center', justifyContent: 'center'}]}>
            <Header navigation={this.props.navigation} width={newWidth} user={this.state.user}/>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <View style={[styles.viewStyle, {width: styling.albumSize, height: styling.albumSize, backgroundColor: '#ee6969'}]}>                
                    <Text style={{textAlign: 'center', marginBottom: 20, color: 'white', fontSize: 20, fontWeight: '900'}}>
                        Error 😔
                    </Text>    
                    <Text style={{textAlign: 'center', color: 'white', fontSize: 20}}>
                        This session doesn't exist or is no longer active
                    </Text>              
              </View>             
              <Button fontSize={styling.buttonFontSize} textColor={'white'} backgroundColor={'#9B9B9B'} width={styling.endBTWidth} height={styling.endBTHeight} text={"Return Home"} 
                  onPress={this.returnHome} style={{marginTop: 30}}
              />  
            </View>
        </View>
      )
    }
    //loading screen
    if (!!!this.state.session || !!!this.state.track){
      return (
        <View style={[styles.container, {alignItems: 'center', justifyContent: 'center'}]}>
          <Header navigation={this.props.navigation} width={newWidth} user={this.state.user}/>
          <View style={{flex: 1, justifyContent: 'center'}}>
          <Loading width={styling.albumSize/2} height={styling.albumSize/2} backgroundColor={"#00D359"}/>
          </View>
        </View>
      );        
    } 

    return (
      <View style={[styles.container, {alignItems: 'center', justifyContent: 'center'}]}>
        <Header navigation={this.props.navigation} width={newWidth} user={this.state.user}/>
        {!!this.state.bannerText && <Banner 
          width={newWidth}
          text={this.state.bannerText}
          backgroundColor={this.state.isError ? '#ee6969' : "#6FCF97"} 
          timeShown={this.state.isError ? 30 : 10}
          hide={()=> this.setState({bannerText: null, isError: false})}
        />}
        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} contentContainerStyle={[styles.contentContainer, {width: newWidth}]}>  
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: styling.headerWidth}}>
                <View style={{width: this.state.width <=700 ? styling.albumSize + 20: null, flexGrow: this.state.width <=700 ? 0 : 1}}>        
                    <Text numberOfLines={2} style={{flex: 1, flexShrink: 1, alignSelf: 'flex-start', fontWeight: '900', fontSize: styling.mainHeader, marginBottom: 10}}>
                      {this.state.session.name}
                    </Text> 
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>    
                        <View style={{alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: styling.alignment, flexDirection: styling.layoutDirection}}>
                            <Text style={{fontSize: styling.subHeader, color: '#808080'}}>
                                <Text style={{fontStyle: 'italic'}}>🔄  Sync Session</Text>
                                <Text> hosted by</Text>
                            </Text>  
                            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: styling.mobileMargin}}>       
                                <Avatar
                                    source={this.state.session.host.image}
                                    size={35}
                                    style={{marginLeft: styling.hostImageMarginLeft, marginRight: 7}}
                                />
                                <TouchableOpacity style={{marginBottom: 5}}>
                                    <Text style={{color: '#808080', fontSize: styling.subHeader, textDecorationLine: 'underline'}}> 
                                      {this.state.session.host.name}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View> 
                        {this.state.width <= 700 && <Button fontSize={styling.buttonFontSize} textColor={'white'} backgroundColor={'#333333'} fontWeight={'regular'} width={styling.inviteBTWidth} height={styling.inviteBTHeight} text={"Share"} 
                            onPress={this.share} image={require('../../assets/images/share.png')}
                        />}             
                    </View>
                </View>
                {this.state.width > 700 && <Button fontSize={styling.buttonFontSize} textColor={'white'} backgroundColor={'#333333'} fontWeight={'regular'} width={styling.inviteBTWidth} height={styling.inviteBTHeight} text={"Invite Others"} 
                    onPress={this.share} image={require('../../assets/images/share.png')}
                />}  
            </View>
            <View style={{flexDirection: styling.layoutDirection, alignItems: 'center', marginTop: styling.mobileMargin * 2}}>
                <View style={[styles.shadow, {borderRadius: styling.albumBorder, height: styling.albumSize, width: styling.albumSize}]}>
                <Image
                    source={this.state.track.image}
                    style={{resizeMode: 'contain', height: styling.albumSize, width: styling.albumSize, borderRadius: styling.albumBorder}}
                />
                </View>
                <View style={{marginLeft: styling.infoMarginLeft, alignSelf: 'flex-start', maxWidth: styling.infoMaxWidth}}>
                    <Text style={{fontWeight: 'bold', color: '#4C4C4C', fontSize: styling.current, marginTop: styling.mobileMargin}}>
                      Currently Playing
                    </Text>
                    <Text numberOfLines={2} style={{width: styling.albumSize, fontWeight: 'bold', color: '#1F1F1F', fontSize: styling.title, marginTop: 10}}>
                      {this.state.track.name}
                    </Text>
                    <Text style={{width: styling.albumSize, color: '#626262', fontSize: styling.artist, marginTop: 10, marginBottom: 30}}>
                        by {this.state.track.artists}
                    </Text>
                    <Listeners numRows={2} fontSize={styling.current} initialNumRender={10} data={this.state.listeners} style={{maxWidth:styling.infoMaxWidth, flexShrink: 1}}/>
                </View>
            </View>    
            <Button fontSize={styling.buttonFontSize} textColor={'white'} width={styling.endBTWidth} height={styling.endBTHeight} 
              text={bottomBT[0]} backgroundColor={bottomBT[1]} loading={this.state.loading}
              onPress={this.bottomBTAction} style={{marginBottom: 20, marginTop: styling.mobileMargin * 2}}
            />      
        </ScrollView>
      </View>
    );
  }
}

SyncScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentContainer: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingTop: 30
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  viewStyle: { 
    alignItems: 'center', 
    justifyContent: 'center', 
    alignSelf: 'center',            
    flexDirection: 'column', 
    borderRadius: 10,    
    padding: 20
},
shadow: {
  shadowColor: '#202020',
  shadowOffset: {width: 0, height: 0},
  shadowRadius: 15,
  shadowOpacity: 0.2
}
});