import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../../components/Header';

export default class SlackUserAuth extends React.PureComponent{
  constructor(props){
    super(props)
  }

  render(){
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation}/>
        <View style={styles.container} contentContainerStyle={styles.contentContainer}>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '80%', height: 500}}>
            <View style={{width: '40%'}}>
              <Text style={{fontWeight: '900', fontSize: 60}}>
                {"Slack User Auth"}
              </Text>
              <Text style={{fontSize: 24, marginTop: '5%'}}>
                Listen to music together in real-time, get secret insights into your friends' music tastes, and survive quarantine with collaborative jam sessions.
              </Text>
              <TouchableOpacity style={{marginTop: '5%', width: '50%'}}>
                <View style={{backgroundColor: '#1DB954', height: 60, alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                    <Text style={{fontSize: 20, fontWeight: '900', color: 'white'}}>
                      💯 START EATING
                    </Text>
                </View>
              </TouchableOpacity>
            </View>           
          </View>
          <View>
            <Text>
              {"Made with ❤️, 🎧, and 😭 by "}
              <Text style={{textDecorationLine: 'underline'}} onPress={() => Linking.openURL('https://jonathanwong.io')}>
                Jonathan Wong
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

SlackUserAuth.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: 'center',
    flexGrow: 1
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  }
});
