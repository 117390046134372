import { useLinking } from '@react-navigation/native';
import { Linking } from 'expo';

export default function(containerRef) {
  return useLinking(containerRef, {
    prefixes: [Linking.makeUrl('/')],
    config: {
      Landing: {
        path: ''
      },
      Home: {
        path: 'home'
      },
      Sync: {
        path: 'sync/:id'
      },
      Group: {
        path: 'group/:id'
      },
      Aux: {
        path: 'aux/:id'
      },
      Profile: {
        path: 'profile'
      },
      Links: {
        path: 'links'
      },
      SpotifyAuth: {
        path: 'auth'
      },
      SlackWSAuth: {
        path: 'slack-ws-auth'
      },
      SlackUserAuth: {
        path: 'slack-user-auth'
      }
    },
  });
}
