export default class WebAPI {
    constructor() {        
    }

    post(endpoint, body, callback= ()=> console.log('success'), error=()=> console.log('error')) {
        fetch(endpoint, {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify(body)
        })
        .then((r) => {                    
            return r.json().then(data => [r.status, data])
        })
        .then((data) => {                            
            if (data[0] === 200){
                callback(data[1]);
            } else{
                error(data[1])
            }            
        }).catch(err => {
            error(err);
        }
        );        
    }    
}