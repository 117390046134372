import * as React from 'react';
import { Image } from 'react-native';

export default class Avatar extends React.PureComponent{
    constructor(props){
        super(props)
        this.state={loaded: true}
    }

    render(){
        let size = !!this.props.size ? this.props.size : 25
        return(
            <Image
                source={this.state.loaded && !!this.props.source ? this.props.source : require('../assets/images/blank_profile.png')}
                style={[{resizeMode: 'contain', height: size, width: size, borderRadius: size}, this.props.style]}
                onError={() => this.setState({loaded: false})}
            />
        );
    }
}