import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../../components/Header';

export default class SpotifyAuth extends React.PureComponent{
  constructor(props){
    super(props)    
  }

  callAuthorization = (code) => {
    fetch('https://api.spotifybuddy.com/user/requestTokensWeb', {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({'code': code})
    })
      .then((response) => {        
        return response.json().then(data => [response.status, data])
      })
      .then((data) => {           
        if (data[0] === 200) {
          localStorage.setItem('user', JSON.stringify(data[1]));
          //if session exists then navigate to it
          if (!!localStorage.getItem('session')) {
            let session = JSON.parse(localStorage.getItem('session'))
            let s = session.type;
            this.props.navigation.navigate(
              s.charAt(0).toUpperCase() + s.slice(1), 
              {id: session.uniqueid_type.split("_")[0]}
            );
          } else {
            this.props.navigation.navigate('Home');
          }          
        } else {
          this.props.navigation.navigate('Landing');
        }
        
      })
      .catch( err => {
        console.log(err)
        this.props.navigation.navigate('Landing');
      })
  }

  componentDidMount(){    
    if (this.props.route.params) {
      if(this.props.route.params.hasOwnProperty('code')){
        this.callAuthorization(this.props.route.params.code);                
      }else{
        this.props.navigation.navigate('Landing');
      }      
    }else{
      this.props.navigation.navigate('Landing');
    }
  }

  render(){
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation}/>
        <View style={styles.contentContainer}>
          <Text style={{fontWeight: '900', fontSize: 60}}>
            {"Authorizing..."}
          </Text>
          <View>
            <Text>
              {"Made with ❤️, 🎧, and 😭 by "}
              <Text style={{textDecorationLine: 'underline'}} onPress={() => Linking.openURL('https://jonathanwong.io')}>
                Jonathan Wong
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

SpotifyAuth.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: 'center',
    flexGrow: 1
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  }
});
