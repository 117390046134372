import React from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { SplashScreen } from 'expo';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LandingScreen from './screens/LandingScreen';
import HomeScreen from './screens/HomeScreen';
import ProfileScreen from './screens/ProfileScreen';
import SlackUserAuth from './screens/authorization/SlackUserAuth';
import SlackWSAuth from './screens/authorization/SlackWSAuth';
import SpotifyAuth from './screens/authorization/SpotifyAuth';
import SyncScreen from './screens/sessions/SyncScreen';
import GroupScreen from './screens/sessions/GroupScreen';
import AuxScreen from './screens/sessions/AuxScreen';
import useLinking from './navigation/useLinking';

const Stack = createStackNavigator();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);
    
  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        // await Font.loadAsync({
        //   // This is the font that we are using for our tab bar          
        //   // 'lato-semibold': require('./assets/fonts/Lato-Semibold.ttf'),
        //   // 'lato-heavy': require('./assets/fonts/Lato-Heavy.ttf'),
        //   'lato-black': require('./assets/fonts/Lato-Black.ttf'),
        //   'lato-bold': require('./assets/fonts/Lato-Bold.ttf'),
        //   'lato-regular': require('./assets/fonts/Lato-Regular.ttf'),          
        //   'lato-italic': require('./assets/fonts/Lato-Italic.ttf'),          
        //   // 'roboto-medium': require('./assets/fonts/Roboto-Medium.ttf'),
        //   'roboto-black': require('./assets/fonts/Roboto-Black.ttf'),
        //   // 'roboto-bold': require('./assets/fonts/Roboto-Bold.ttf'),
        //   'roboto-regular': require('./assets/fonts/Roboto-Regular.ttf'),
        // });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <NavigationContainer ref={containerRef} initialState={initialNavigationState}>
          <Stack.Navigator screenOptions={{headerShown: false}}>
            <Stack.Screen name="Landing" component={LandingScreen} /> 
            <Stack.Screen name="Home" component={HomeScreen} /> 
            <Stack.Screen name="Profile" component={ProfileScreen} /> 
            <Stack.Screen name="Sync" component={SyncScreen} /> 
            <Stack.Screen name="Group" component={GroupScreen} /> 
            <Stack.Screen name="Aux" component={AuxScreen} /> 
            <Stack.Screen name="SlackWSAuth" component={SlackWSAuth} />
            <Stack.Screen name="SlackUserAuth" component={SlackUserAuth} />
            <Stack.Screen name="SpotifyAuth" component={SpotifyAuth} />            
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    ); 
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
