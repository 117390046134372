import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Picker, TextInput, StyleSheet, Text, TouchableOpacity, Image, View, Linking, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../components/Header';
import SegmentedControlTab from "react-native-segmented-control-tab";
import FormInput from '../components/FormInput';
import Button from '../components/Button';
import Banner from '../components/Banner';
import Avatar from '../components/Avatar';
import webAPI from '../utils/web_api/api';

const api = new webAPI();

export default class HomeScreen extends React.PureComponent{
  constructor(props){
    super(props);
    this.state= {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,      
      selectedIndex: 1,    
      playbackIndex: 0,
      queueIndex: 0,  
      checkingLink: false,
      loadingSession: false,
      bannerText: localStorage.getItem('onboarded') ? "" : "❕Before creating a session, make sure to have a song playing in Spotify to establish a connection",      
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
      session: localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : null,
    }
    if(!!this.state.user) {
      this.state.name = this.state.user.name + "'s Group Jam"
      this.state.link =  (this.state.user.name + " music").toLowerCase().replace(/\s/g, '-')  
    }    
  }

  componentDidMount(){     
    Dimensions.addEventListener("change", this.handler);
    if(!!!this.state.user){
      this.props.navigation.navigate('Landing');
    } else{
      this.loadHome();
      this.isValidLink(this.state.link);       
    }    
  }

  componentWillUnmount() {
    // Important to stop updating state after unmount  
    Dimensions.removeEventListener("change", this.handler);
  }

  handler = (dims) => {    
    this.setState({
      width: dims.window.width,
      height: dims.window.height,
    });
  }

  handleQueueIndexChange = index => {   
    var selectedIndex = 0;
    let queueIndex = parseInt(index);
    if (queueIndex === 0) {
      if (this.state.playbackIndex === 0) {
        //group jam
        selectedIndex = 1;
      } else {
        //aux party
        selectedIndex = 0;
      }
    } else {
      if (this.state.playbackIndex === 0) {
        //sync session
        selectedIndex = 2;
      }
    }

    this.setState({
      ...this.state,
      selectedIndex: selectedIndex,
      name: this.state.user.name + "'s " + sessionTypes[selectedIndex].name,
      queueIndex: queueIndex      
    }, 
      () =>{
        this.isValidLink(this.state.link)
      }
    );    
  }

  handlePlaybackIndexChange = index => {        
    var selectedIndex = 0;
    let playbackIndex = parseInt(index);
    if (this.state.queueIndex === 0) {
      if (playbackIndex === 0) {
        //group jam
        selectedIndex = 1;
      } else {
        //aux party
        selectedIndex = 0;
      }
    } else {
      if (playbackIndex === 0) {
        //sync session
        selectedIndex = 2;
      }
    }

    this.setState({
      ...this.state,
      selectedIndex: selectedIndex,
      name: this.state.user.name + "'s " + sessionTypes[selectedIndex].name,
      playbackIndex: playbackIndex    
    }, 
      () =>{
        this.isValidLink(this.state.link)
      }
    );   
  }

  handleNameChange = name => {
    this.setState({      
      name: name
    });
  }

  handleLinkChange = link => {
    let length = 40;
    const revisedLink = link.toLowerCase().replace(/\s/g, '-').replace(/[^\w-]/g,'').substring(0, length);
    
    if (this.state.checkingLink) {
      this.setState({link: revisedLink});   
    } else if(!!revisedLink){      
      this.setState({link: revisedLink, checkingLink: true});
      this.isValidLink(revisedLink);            
    } else{
      this.setState({link: revisedLink, validLink: false}); 
    }
  }

  isValidLink = (link) => {    
    let body = {
      link: link,
      workspace_userid: this.state.user.workspace_userid,
      type: sessionTypes[this.state.selectedIndex].link.toLowerCase()
    }      
    var callback = (data) => {           
      this.setState({                
        validLink: data.unique_link,
        checkingLink: false       
      }); 
    }

    api.post(
      'https://api.spotifybuddy.com/checkLink', 
      body,
      callback=callback 
    )
  }

  componentDidUpdate(prevProps) {
    if (localStorage.getItem('session') === null) {
      this.refreshSession();
    }
  }

  refreshSession = () => {
    this.setState({
      session: localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : null
    })
    this.isValidLink(this.state.link)
  }

  loadHome = () => {             
    var callback = (data) => {                 
      var body = {}
      if ("session" in data) {
        localStorage.setItem('session', JSON.stringify(data.session));
        body.session = data.session;
      } else {
        localStorage.removeItem('session');
        body.session = null
      }
      if ("user" in data) {
        localStorage.setItem('user', JSON.stringify(data.user));
        body.user = data.user;
      }
      this.setState(body); 
    }

    api.post(
      'https://api.spotifybuddy.com/user/loadHome', 
      {workspace_userid: this.state.user.workspace_userid},
      callback=callback 
    )
  }

  startSession = () => {    
    if (!this.state.validLink) return;
    this.setState({                
      loadingSession: true
    }); 

    let body = {
      link: this.state.link,
      workspace_userid: this.state.user.workspace_userid,
      name: this.state.name,
      type: sessionTypes[this.state.selectedIndex].link.toLowerCase()
    }

    var callback = (data) => {            
      let session = {
        type: sessionTypes[this.state.selectedIndex].link,        
        name: this.state.name,
        uniqueid_type: this.state.link + "_" + sessionTypes[this.state.selectedIndex].link.toLowerCase(),
        host: this.state.user
      }
      localStorage.setItem('session', JSON.stringify(session));
      localStorage.setItem('onboarded', "true");
      this.setState({                
        loadingSession: false,
        session: session,
        bannerText: ""
      }); 
      this.props.navigation.navigate(session.type, {id: session.uniqueid_type.split("_")[0]});
    }
    var error = (err) => {            
      this.setState({
        bannerText: "😔 Error: " + err.error,        
        loadingSession: false
      })
    }
    api.post(
      'https://api.spotifybuddy.com/startSession', 
      body,
      callback=callback,
      error=error
    )    
  } 
  
  getIndex = () => {
    return sessionIndex[this.capitalize(this.state.session.type)]
  }

  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  render(){
    let widthCutoff = 1000;
    var styling = {}
    if (this.state.width <= widthCutoff){
      styling = {
        width: '90%',
        buttonWidth: 300,
        segmentWidth: '100%'
      }
    }else{
      styling = {
        width: 1000,
        buttonWidth: 375,
        segmentWidth: '70%'
      }
    }   
    if (!!!this.state.user) return null; 
    return (
      <View style={[styles.container, {alignItems: 'center', justifyContent: 'center'}]}>
        <Header navigation={this.props.navigation} width={styling.width} user={this.state.user}/>
        {false && <Text>
          {"Width: " + this.state.width.toString() + " Height: " + this.state.height.toString()}
        </Text>}
        {!!this.state.bannerText && <Banner 
          width={styling.width}
          text={this.state.bannerText}
          backgroundColor={(this.state.bannerText.includes("Error")) ? '#ee6969' : "#6FCF97"}
          timeShown={30}
          hide={()=> this.setState({bannerText: null})}
        />}
        <ScrollView style={[styles.container, {width: styling.width}]} contentContainerStyle={[styles.contentContainer]}>                    
          <View style={{width: styling.segmentWidth}}> 
            <Text style={{alignSelf: 'flex-start', fontWeight: '900', fontSize: 30, marginBottom: 30}}>
            🎧 Listen Together
            </Text> 
            { !(!!this.state.session) && 
            <View> 
              <Text style={styles.inputDescription}>
                Who Queues Songs
              </Text>   
              { this.state.width >= 500 &&
                <SegmentedControlTab
                    values={["👥   Anyone", "👤   Just Me"]}
                    selectedIndex={this.state.queueIndex}
                    onTabPress={this.handleQueueIndexChange}             
                    tabTextStyle={{fontSize: 20, color: 'black'}}
                    activeTabTextStyle={{fontWeight: '700', fontSize: 20, color: 'black'}}
                    activeTabStyle={{backgroundColor: '#EBEBEB'}}   
                    tabStyle={{borderColor: '#D8D8D8', borderWidth: 2}}                           
                    tabsContainerStyle={{width: '100%', height: 50}}
                /> 
              }
              { this.state.width < 500 && 
                <Picker onValueChange={this.handleQueueIndexChange} selectedValue={this.state.queueIndex} style={{width: '100%', borderRadius: 5, height: 50, borderColor: '#D8D8D8', padding: 5, alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}}>
                  <Picker.Item label="👥   Anyone" value={0}/>
                  <Picker.Item label="👤   Just Me" value={1}/>                
                </Picker> 
              }
              <Text style={[styles.inputDescription, {marginTop: 20}]}>
                Music Plays On
              </Text>
              { this.state.width >= 500 &&
                <SegmentedControlTab
                    values={["🔊   Multiple Devices", "🔇   Just Yours"]}
                    selectedIndex={this.state.playbackIndex}
                    onTabPress={this.handlePlaybackIndexChange}             
                    tabTextStyle={{fontSize: 20, color: 'black'}}
                    activeTabTextStyle={{fontWeight: '700', fontSize: 20, color: 'black'}}
                    activeTabStyle={{backgroundColor: '#EBEBEB'}}   
                    tabStyle={{borderColor: '#D8D8D8', borderWidth: 2}}                           
                    tabsContainerStyle={{width: '100%', height: 50}}
                /> 
              }
              { this.state.width < 500 && 
                <Picker onValueChange={this.handlePlaybackIndexChange} selectedValue={this.state.playbackIndex} style={{width: '100%', borderRadius: 5, height: 50, borderColor: '#D8D8D8', padding: 5, alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}}>
                  <Picker.Item label="🔊   Multiple Devices" value={0}/>
                  <Picker.Item label="🔇   Just Yours" value={1}/>                
                </Picker> 
              }                     
              {false && <Text style={{fontSize: this.state.width < 500 ? 15: 20, color: '#C5C5C5', textAlign: 'center', marginTop: 10, marginBottom: 20}}>
                {sessionTypes[this.state.selectedIndex].description}
              </Text> } 
              <Text style={[styles.inputDescription, {marginTop: 20}]}>
                Name
              </Text>  
              <FormInput 
                initialText={'👻'} 
                onChangeText={this.handleNameChange} 
                value={this.state.name}
                padInput={10}
                placeholder={'Enter a name for your listening session'}
              />                
              <Text style={styles.inputDescription}>
                Invite Link
              </Text>  
              <FormInput 
                initialText={"/" + sessionTypes[this.state.selectedIndex].link.toLowerCase() + "/"} 
                onChangeText={this.handleLinkChange} 
                value={this.state.link}
                placeholder={'Enter a unique link'}    
                valid={this.state.validLink}          
              />  
              <Button 
                fontSize={20} textColor={'white'} backgroundColor={'#00D359'} borderRadius={5}
                width={styling.buttonWidth} height={50} text={"Start Session"} 
                onPress={this.startSession} fontWeight={'900'} style={{margin: '3%', alignSelf: 'center'}} loading={this.state.loadingSession}
              />                  
            </View>    
            }
            {!!this.state.session && 
              <View style={{alignItems: 'center'}}>        
                <Text style={[styles.inputDescription, {alignSelf: 'flex-start'}]}>
                  Currently Playing
                </Text>
                <Text numberOfLines={2} style={{flex: 1, flexShrink: 1, fontWeight: 'bold', fontSize: 24, marginBottom: 15, marginTop: 20}}>
                    {this.state.session.name}
                </Text> 
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>    
                    <View style={{alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>
                        <Text style={{fontSize: 15, color: '#808080'}}>
                            <Text style={{fontStyle: 'italic'}}>{sessionTypes[this.getIndex()].emoji + " "} {sessionTypes[this.getIndex()].name}</Text>
                            <Text> hosted by</Text>
                        </Text>  
                        <View style={{flexDirection: 'row', alignItems: 'center', marginsTop: 5}}>       
                            <Avatar
                                source={this.state.session.host.image}
                                size={35}
                                style={{marginLeft: 10, marginRight: 7}}
                            />
                            <TouchableOpacity style={{marginBottom: 5}}>
                                <Text style={{color: '#808080', fontSize: 15, textDecorationLine: 'underline'}}> 
                                {this.state.session.host.name}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>          
                </View>
                <Button 
                  fontSize={20} textColor={'white'} backgroundColor={'#00D359'} borderRadius={5}
                  width={styling.buttonWidth} height={50} text={"Go To Session"} 
                  onPress={() => {
                    this.props.navigation.navigate(
                      this.capitalize(this.state.session.type), 
                      {id: this.state.session.uniqueid_type.split("_")[0]}
                    )
                }} 
                  fontWeight={'900'} style={{marginTop: 20, alignSelf: 'center'}}
                />    
              </View> 
            }
            <View style={{marginTop: 10, alignSelf: 'center'}} accessibilityRole="link" target="_blank" href="https://forms.gle/qZkCa7qzd41uWUh96">
              <Text style={{color: 'gray', textDecorationLine: 'underline', fontWeight: '300'}}>
                Give Feedback
              </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

HomeScreen.navigationOptions = {
  header: null,
};

const sessionTypes = [
  {name: "Aux Party", emoji: "🔊", link: 'Aux', description: "Let others queue songs to your aux (Spotify account)"},
  {name: "Group Jam", emoji: "💯", link: 'Group', description: "Let others queue songs and listen to what you're playing"},
  {name: "Sync Session", emoji: "🔄", link: 'Sync', description: "Let others listen along to what you're currently playing"},
]

const sessionIndex = {Aux: 0, Group: 1, Sync: 2}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentContainer: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingTop: 30
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  inputDescription: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10
  }
});
