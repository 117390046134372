import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../../components/Header';

export default class SlackWSAuth extends React.PureComponent{
  constructor(props){
    super(props)
    this.state = {
      authorized: 'pending'
    }
  }

  componentDidMount(){
    if (this.props.route.params) {
      if(this.props.route.params.hasOwnProperty('authorized')){
        // this.callAuthorization(this.props.route.params.code);
        this.setState({authorized: this.props.route.params.authorized})
      }else{
        this.setState({authorized: 'failed'})
      }      
    }else{
      this.setState({authorized: 'failed'})
    }
  }

  // callAuthorization = (code) => {
  //   fetch('https://api.spotifybuddy.com/workspace/auth', {
  //     method: 'post',
  //     body: JSON.stringify({'code': code})
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if(data.ok) {
  //         this.setState({authorized: 'success'})
  //       } else{
  //         this.setState({authorized: 'failed'})
  //       }
  //     });
  // }

  render(){
    var headerText = ""
    var image = ""
    var bodyText = ""
    if (this.state.authorized == 'pending'){
      headerText = "Finishing Authorization with Slack"
      bodyText = "Wait a second..."
      image = "https://i.giphy.com/media/5xtDarzqYMWFigufLws/source.gif"
    } else if(this.state.authorized == 'failed'){
      headerText = "Authorization with Slack Failed"
      bodyText = "Go through the authorization process again and accept this time!"
      image = "https://i.giphy.com/media/nKN7E76a27Uek/source.gif"
    } else{
      headerText = "Authorization with Slack Succeeded!"
      bodyText = "You're good to go. You can now use Spotify Buddy in your workspace!"
      image = "https://i.giphy.com/media/XreQmk7ETCak0/source.gif"
    }
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation}/>
        <View style={styles.contentContainer}>              
          <Text style={{fontWeight: '900', fontSize: 50, textAlign: 'center', marginTop: '10%'}}>
            {headerText}
          </Text>     
          <Text style={{fontSize: 24, textAlign: 'center', marginTop: '5%'}}>
            {bodyText}
          </Text>    
          <Image
            source={image}
            style={{resizeMode: 'contain', width: '50%', height: '50%'}}
          />      
          <View style={{margin: 75}}>
            <Text style={{fontSize: 15, textAlign: 'center'}}>
              {"Made with ❤️, 🎧, and 😭 by "}
              <Text style={{textDecorationLine: 'underline'}} onPress={() => Linking.openURL('https://jonathanwong.io')}>
                Jonathan Wong
              </Text>
            </Text>
          </View>
        </View>      
      </View>
    );
  }
}

SlackWSAuth.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center'
  },
  contentContainer: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: 'center',
    flexGrow: 1
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  }
});
