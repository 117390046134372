import * as React from 'react';
import { Text, TouchableOpacity, View} from 'react-native';
import ScaledImage from './ScaledImage';

export default class Button extends React.PureComponent{
    constructor(props){
        super(props);
    }
    render(){        
        var newStyle = [this.props.style]
        if (!!this.props.style) newStyle.push({width: this.props.width})
        let buttonStyle = {
            backgroundColor: this.props.backgroundColor,  
            height: this.props.height, 
            width: this.props.width,
            alignItems: 'center', 
            justifyContent: 'center', 
            flexDirection: 'row', 
            borderRadius: this.props.borderRadius ? this.props.borderRadius : 10,
            borderWidth: this.props.borderWidth ? this.props.borderWidth : 0,
            borderColor: this.props.borderColor ? this.props.borderColor : 'white',
        }
        return (
            <TouchableOpacity style={newStyle} onPress={this.props.onPress} disabled={!!this.props.loading}>
                <View style={[buttonStyle]}>
                    {(this.props.image && !this.props.loading) && 
                    <ScaledImage
                        source={this.props.image}
                        height={this.props.height * 0.5}
                        style={{marginRight: 5}}
                    />}
                    {(this.props.loading) && 
                    <ScaledImage
                        source={require('../assets/images/loading.gif')}
                        height={this.props.height * 0.3}
                        style={{marginRight: 5}}
                    />}
                    <Text style={{fontSize: this.props.fontSize ? this.props.fontSize : 20, fontWeight: this.props.fontWeight ? this.props.fontWeight : 'bold', color: this.props.textColor}}>                        
                        {!!this.props.loading ? "" : this.props.text}
                    </Text>
                </View>
            </TouchableOpacity>
        )
    }
}