import * as React from 'react';
import { Text, TouchableOpacity, View} from 'react-native';
import ScaledImage from './ScaledImage';

export default class Loading extends React.PureComponent{
    constructor(props){
        super(props);
    }
    render(){                        
        let buttonStyle = {
            backgroundColor: this.props.backgroundColor,  
            height: this.props.height, 
            width: this.props.width,
            alignItems: 'center', 
            justifyContent: 'center', 
            alignSelf: 'center',            
            flexDirection: 'column', 
            borderRadius: this.props.borderRadius ? this.props.borderRadius : 10,
            borderWidth: this.props.borderWidth ? this.props.borderWidth : 0,
            borderColor: this.props.borderColor ? this.props.borderColor : 'white',
        }
        return (            
            <View style={[buttonStyle, this.props.style]}>                
                {false && <Text style={{color: 'white', fontSize: 20}}>
                    {"Loading..."}
                </Text>}
                <ScaledImage
                    source={require('../assets/images/loading.gif')}
                    width={this.props.width * 0.5}
                    style={{}}
                />                
            </View>            
        )
    }
}