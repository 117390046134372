import * as React from 'react';
import { Picker, TextInput, StyleSheet, Text, TouchableOpacity, Image, View, Linking, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Header} from '../components/Header';
import Button from '../components/Button';
import Avatar from '../components/Avatar';

export default class ProfileScreen extends React.PureComponent{
  constructor(props){
    super(props);
    this.state= {
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,          
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null      
    }       
  }

  componentDidMount(){         
    if(!!!this.state.user){
      this.props.navigation.navigate('Landing');
    } else {
        Dimensions.addEventListener("change", this.handler);
    }
  }

  componentWillUnmount() {
    // Important to stop updating state after unmount  
    Dimensions.removeEventListener("change", this.handler);
  }

  handler = (dims) => {    
    this.setState({
      width: dims.window.width,
      height: dims.window.height,
    });
  }

  capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  logOut = () => {
    localStorage.clear();
    this.props.navigation.navigate('Landing')
  }

  render(){
    let widthCutoff = 1000;
    var styling = {}
    if (this.state.width <= widthCutoff){
      styling = {
        width: '90%',
        buttonWidth: 300,
        segmentWidth: '100%',
        avatarSize: 100
      }
    }else{
      styling = {
        width: 1000,
        buttonWidth: 375,
        segmentWidth: '70%',
        avatarSize: 150
      }
    }   
    if (!!!this.state.user) return null; 
    return (
      <View style={[styles.container, {alignItems: 'center', justifyContent: 'center'}]}>
        <Header navigation={this.props.navigation} width={styling.width} user={this.state.user}/>        
        <ScrollView style={[styles.container, {width: styling.width}]} contentContainerStyle={[styles.contentContainer]}>                    
            <Avatar
              source={!!this.state.user.image ? this.state.user.image : ""}
              size={styling.avatarSize}
            />
          <Text style={{fontWeight: '900', fontSize: 30, marginTop: 30}}>
            {this.state.user.name}
          </Text>   
          <Button 
            fontSize={20} textColor={'black'} backgroundColor={'white'} borderRadius={5} borderWidth={2} borderColor={'#D3D3D3'}
            width={styling.buttonWidth} height={50} text={"Log Out"}
            onPress={this.logOut} fontWeight={'regular'} style={{margin: '3%', alignSelf: 'center'}}
          />           
        </ScrollView>
      </View>
    );
  }
}

ProfileScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentContainer: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingTop: 30
    // flex: 1
  },
  welcomeContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  inputDescription: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10
  }
});
