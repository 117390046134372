import React, { Component, PropTypes } from "react";
import { Image } from "react-native";

export default class ScaledImage extends Component {
constructor(props) {
   super(props);
   this.state = {};
   Image.getSize(props.source, (width, height) => {
       this.setState({
           width: width,
           height: height
       })
    });
}

render() {  
    var dimensions = {};  
    if (this.props.width && !this.props.height) {
        dimensions = {
            width: this.props.width,
            height: this.state.height * (this.props.width / this.state.width)
        };
    } else if (!this.props.width && this.props.height) {
        dimensions = {
            width: this.state.width * (this.props.height / this.state.height),
            height: this.props.height
        };
        if (!!this.props.screenWidth) {
            if (dimensions.width > this.props.screenWidth) {
                dimensions = {
                    width: this.props.screenWidth,
                    height: this.state.height * (this.props.screenWidth / this.state.width)
                };
            }
        }
    } else {
        dimensions = { width: this.state.width, height: this.state.height };
    }    
   return (
       <Image
           source={this.props.source}
           style={[{resizeMode: 'contain', height: dimensions.height, width: dimensions.width}, this.props.style]}
       />
   );
}
}

// ScaledImage.propTypes = {
// source: PropTypes.string.isRequired,
// width: PropTypes.number,
// height: PropTypes.number
// };